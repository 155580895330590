import {Link} from "react-router-dom";
import {Stat} from "../dashboards/Stat";


export const StatManager = ({pageDetail, children}) => {

    return (
        <div>
            <div className="flex flex-row items-center justify-between ">
                <p className='font-sans pl-4 mt-8 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {pageDetail.name}
                </p>
                {
                    pageDetail.link ?
                    <Link to={pageDetail.link}>
                    <button className="btn btn-sm w-full max-w-xs">See Details</button>
                    </Link> :
                    null
                }
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 gap-4">
                {children}
            </div>
        </div>
    )
}