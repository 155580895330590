import {useEffect, useState} from "react";
import {filterDefault, PageNames} from "../data/options";
import {TenantSelector} from "./base/Selector";
import {Stat} from "./dashboards/Stat";
import {overviewDashboardSchema, overviewDataMap, statSchema} from "../data/chartSchemas";
import apiCall from "./base/call";
import moment from "moment";
import {StatManager} from "./base/StatManager";
import {extractStatsArrangedByCol, prepareLabourStat} from "../utils/overviewPageTransforms";
import {InventoryOverview} from "./dashboards/InventoryOverview";


export const OverviewPage = ({auth, setAlert}) => {
    const [data, setData] = useState(overviewDataMap)
    const [stats, setStats] = useState(overviewDashboardSchema)
    const [filters, setFilters] = useState(filterDefault)

    // apply right filter range
    useEffect(() => {
        let start_date = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        let end_date = moment().format('YYYY-MM-DD')
        setFilters({...filters, ['start_date']: start_date, ['end_date']: end_date})
    }, [])

    // upload all the data
    const handleClick = () => {
        console.log('call OVERVIEW1')
        for (const [key, value] of Object.entries(data)) {
            apiCall(setAlert, value.endpoint, filters, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    value.data = resp.data
                    setData({...data})

                }
                else {
                    value.data = false
                    setData({...data})
                }
            })
        }
        setFilters({...filters, ['filter']: !filters.filter})
    }

    // single effect for a single data array
    useEffect(() => {
        console.log('Preparing of STATS')
        Object.keys(stats).forEach((key) => {stats[key].value = statSchema}) // cleaning up empty stats

        if (data.sales.data) {
            stats.stores.value.main = data.sales.data.length
            stats.netSales.value = extractStatsArrangedByCol(data.sales.data, '_net_sales', '_prev_net_sales')
            stats.ticket.value = extractStatsArrangedByCol(data.sales.data, '_avg_net_ticket', '_prev_avg_net_ticket')
            stats.transactions.value = extractStatsArrangedByCol(data.sales.data, '_transactions', '_prev_transactions')
            stats.customers.value = extractStatsArrangedByCol(data.sales.data, '_unique_customers', '_prev_unique_customers')

            stats.debtRatio.value = extractStatsArrangedByCol(data.sales.data, '_avg_debt', '_prev_avg_debt')
            stats.debtRatio.value.trend.unit = null
            stats.debtRatio.value.trend.direction = !stats.debtRatio.value.trend.direction

            stats.labourRatio.value = prepareLabourStat(data.sales.data)
        }

        if (data.margin.data) {
            stats.margin.value = extractStatsArrangedByCol(data.margin.data, '_margin', '_prev_margin')
            stats.marginPct.value = extractStatsArrangedByCol(data.margin.data, '_margin_pct', '_prev_margin_pct')
        }

        if (data.loyalCustomers.data) {
            stats.loyalCustomers.value = extractStatsArrangedByCol(data.loyalCustomers.data, '_curr_m_loyal', '_prev_m_loyal')
        }

        if (data.newCustomers.data) {
            stats.newCustomers.value = extractStatsArrangedByCol(data.newCustomers.data, '_new_customers', '_prev_new_customers')
        }

        if (data.lostCustomers.data) {
            stats.lostCustomers.value = extractStatsArrangedByCol(data.lostCustomers.data, '_lost_customers', '_prev_lost_customers')
        }

        if (data.returnCustomers.data) {
            stats.returnedCustomers.value = extractStatsArrangedByCol(data.returnCustomers.data, '_avg_curr_month_hit', '_avg_prev_month_hit')
        }

        setStats({...stats})
    }, [data])

    console.log('OV render')
    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between mb-4">
                <p className='font-sans pl-4 mt-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.OVERVIEW.name}
                </p>
            </div>
            <div className="flex flex-row items-center">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} auth={auth} allTenants name='tenant_id'/>
                <button className="btn btn-sm w-sm max-w-xs" onClick={handleClick}>Show Statistics</button>
            </div>

            <StatManager pageDetail={PageNames.PERFORMANCE}>
                <Stat {...stats.stores} />
                <Stat {...stats.netSales}/>
                <Stat {...stats.transactions}/>
                <Stat {...stats.ticket}/>
                <Stat {...stats.margin}/>
                <Stat {...stats.marginPct}/>
            </StatManager>

            <StatManager pageDetail={PageNames.FINANCIAL}>
                <Stat {...stats.debtRatio}/>
                <Stat {...stats.labourRatio}/>
                <Stat {...stats.liabilityRatio}/>
            </StatManager>

            <StatManager pageDetail={PageNames.CUSTOMER}>
                <Stat {...stats.customers}/>
                <Stat {...stats.loyalCustomers}/>
                <Stat {...stats.returnedCustomers}/>
                <Stat {...stats.newCustomers}/>
                <Stat {...stats.lostCustomers}/>
            </StatManager>

            <InventoryOverview setAlert={setAlert} filters={filters} auth={auth}/>
        </div>
    )
}
