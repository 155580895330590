import {PageNames} from "../../data/options";
import {Stat} from "./Stat";
import {StatManager} from "../base/StatManager";
import {useEffect, useState} from "react";
import {
    inventoryDashboardSchema,
    inventoryDataMap,
    statSchema
} from "../../data/chartSchemas";
import apiCall from "../base/call";
import {
    extractStatsArrangedByCol,
    prepareDistressedInvStat,
    assignValue
} from "../../utils/overviewPageTransforms";


export const InventoryOverview = ({setAlert, filters, auth}) => {
    const [data, setData] = useState(inventoryDataMap)
    const [stats, setStats] = useState(inventoryDashboardSchema)

    useEffect (() => {
        for (const [key, value] of Object.entries(data)) {
            apiCall(setAlert, value.endpoint, filters, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    value.data = resp.data
                    setData({...data})
                } else {
                    value.data = false
                    setData({...data})
                }
            })
        }
    }, [filters.filter])

    useEffect(() => {
        console.log('Preparing of STATS')
        Object.keys(stats).forEach((key) => {stats[key].value = statSchema}) // cleaning up empty stats

        if (data.inventory.data && data.inventoryDistress.data && data.exactInventoryValue.data) {
            stats.skuStock.value = prepareDistressedInvStat('_total_sku', '_distressed_sku', data.inventory.data, data.inventoryDistress.data)
            stats.inventoryCost.value = prepareDistressedInvStat('_inv_cost', '_distressed_cost', data.inventory.data, data.inventoryDistress.data)
            stats.inventoryQty.value = prepareDistressedInvStat('_inv_qty', '_distressed_qty', data.inventory.data, data.inventoryDistress.data)

            stats.burnRate.value = extractStatsArrangedByCol(data.inventory.data, '_units_sold_per_day')
            stats.dailyCogs.value = extractStatsArrangedByCol(data.inventory.data, '_daily_cogs')
            stats.daysTurnover.value = extractStatsArrangedByCol(data.inventory.data, '_days_to_turnover')

            stats.stockCost.value = assignValue(data.exactInventoryValue.data, '_exact_inv_cost')
            stats.streetValue.value = assignValue(data.exactInventoryValue.data, '_exact_inv_price')
        }

        setStats({...stats})
    }, [data])

    return (
        <StatManager pageDetail={PageNames.OVERVIEW}>
            <Stat {...stats.skuStock}/>
            <Stat {...stats.inventoryCost}/>
            <Stat {...stats.inventoryQty}/>
            <Stat {...stats.burnRate}/>
            <Stat {...stats.dailyCogs}/>
            <Stat {...stats.daysTurnover}/>

            <Stat {...stats.stockCost}/>
            <Stat {...stats.streetValue}/>
        </StatManager>
    )
}