export const PageNames = {
    HOME: {name: 'Racetrack', link: '/'},
    OVERVIEW: {name: 'Overview', link: '/overview'},
    STORES: {name: 'Stores', link: '/stores-list'},
    PERFORMANCE: {name: 'Performance', link: '/store-performance'},
    INVENTORY: {name: 'Inventory', link: '/inventory'},
    LOGIN: {name: 'Log In', link: '/'},
    LOGOUT: {name: 'Log Out', link: '/'},
    FINANCIAL: {name: 'Financial', link: '/store-performance'},
    CUSTOMER: {name: 'Customers', link: '/store-performance'}
}

export const AlertTypes = {
    WARNING : {style: 'alert alert-warning my-4', icon: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>},
    ERROR: {style: 'alert alert-error my-4', icon: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>},
    SUCCESS: {style: 'alert alert-success my-4', icon: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>},
    INFO: {style: 'alert alert-info my-4', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>},
    ALERT: {style: 'alert my-4', icon:  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    }
}

export const PageLink = {
    HOME: 'Racetrack',
    OVERVIEW: 'Overview',
    STORES: 'Stores',
    PERFORMANCE: 'Performance',
    INVENTORY: 'Inventory',
    LOGIN: 'Log In',
    LOGOUT: 'Log Out'
}

export const filterDefault = {
    tenant_id: 0,
    start_date: '2023-01-01',
    end_date: '2023-12-01',
    period: 'month',
    r_avg: 1,
    filter: false,
    selected_pid: undefined
}

export const defaultTenantPlaceholder = [{'_tenant_id':0, '_company_name': 'No Company', '_store_name': 'Selected'}]
export const periodOptions = ['month', 'week', 'day']
export const movingAvg = [1,2,3,4,5,6,7]