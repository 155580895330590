import axios from "axios";
import {AlertTypes} from "../../data/options";
import Auth from "./Auth";

const apiCall = (setAlert, endpoint, filters, auth = Auth) => {
    console.log('Calling endpoint, filters', endpoint, filters)
    const nginx_url =  `${window.location.protocol}//api.${window.location.hostname}/api/${endpoint}`
    // let token = sessionStorage.getItem('tk')
    // if (auth) token = auth.jwtToken
    let header = {headers: {'Authorization': 'Bearer ' + auth.cls.jwtToken}}
    return axios.post(nginx_url, filters, header).catch(error => {
            let alert = {type: AlertTypes.ERROR, msg: error.message, visible: true}
            setAlert(alert)
        });
}

export default apiCall