export const assignValue = (data, colName, precision=2) => {
    let dt = data ? data[0][colName] : null
    return {
        main: dt.toFixed(precision),
        trend: false
    }
}


export const extractStatsArrangedByCol = (data, currPeriodColName, prevPeriodColName, precision=2) => {
    let currPeriodTenants = data.filter((obj) => obj[currPeriodColName] > 0).length
    let currPeriodValue = data.map(item => item[currPeriodColName]).reduce((prev, next) => prev + next) / currPeriodTenants

    if (!prevPeriodColName) {
        return {
            main: currPeriodValue.toFixed(precision),
            trend: false
        }
    }
    let prevPeriodTenants = data.filter((obj) => obj[prevPeriodColName] > 0).length
    let prevPeriodValue = data.map(item => item[prevPeriodColName]).reduce((prev, next) => prev + next) / prevPeriodTenants
    let trendChangePct = (currPeriodValue - prevPeriodValue) / prevPeriodValue * 100

    return {
        main: currPeriodValue.toFixed(precision),
        trend: {
            points: trendChangePct.toFixed(precision),
            direction: trendChangePct > 0,
            unit: '%'
        }
    }
}

export const pctBetweenTwoDimensions = (dt, mainDimension, compareDimension) => {
    let mainDimensionSum = 0
    let compareDimensionSum = 0
    let count = 0
    for (const [key, value] of Object.entries(dt)) {
        let main = value[mainDimension]
        let compare = value[compareDimension]
        if (main) {
            mainDimensionSum += main
            compareDimensionSum += compare
            count += 1
        }
    }
    return (mainDimensionSum/count) / ( (compareDimensionSum/count) / 100)
}

export const prepareLabourStat = (dt) => {
    let currPct = pctBetweenTwoDimensions(dt, '_curr_avg_labour', '_net_sales')
    let prevPct = pctBetweenTwoDimensions(dt, '_prev_avg_labour', '_prev_net_sales')

    return {
        main: currPct.toFixed(2),
        trend: {
            points: prevPct.toFixed(2),
            direction: currPct < prevPct,
            unit: '%'
        }
    }
}

export const prepareDistressedInvStat = (baseDimensionName, distrDimensionName, baseData, distrData) => {
    let baseValue = extractStatsArrangedByCol(baseData, baseDimensionName)
    let distressedSkuValue = extractStatsArrangedByCol(distrData, distrDimensionName).main
    let trendSkuPoints = (distressedSkuValue / (baseValue.main / 100)).toFixed(2)
    baseValue.trend = {points: trendSkuPoints, direction: null, unit: 'distr %'}
    return baseValue
}