import {Table} from "../charts/Table";


export const InventoryTable = ({data, filters, setFilters}) => {
    console.log('rendering IT')
    return(
        <div className="flex flex-row space-x-4">
            <Table data={data}> filters={filters} setFilters={setFilters}</Table>
        </div>
    )
};

